import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player";
import Vimeo from "react-player/lib/players/Vimeo";
import styled from "styled-components";
import { BulletList } from "../components";
import { device } from "utils";

const WhyCloudLabReproducibilityTraditional = ({ data }) => {
  const {
    content: [row1Content, row2Content, row3Content],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Row1TextNode>{row1Content[0].text}</S.Row1TextNode>
      </S.Row1>
      <S.Row2>
        <S.VideoPlayer
          url={row2Content[0].videoURL}
          loop
          muted
          playing
          playsinline
          width="100%"
          height={0}
          widescreen="false"
          {...row2Content[0].controls}
        />
      </S.Row2>
      <S.Row3>
        <S.Row3TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row3Content,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row3TextNode>
        <S.ImageContainer>
          <S.ImageSmallNode>
            <S.ImageTitle>{row3Content[1].title}</S.ImageTitle>
            <S.Image
              src={row3Content[1].image}
              loading="lazy"
              alt="inconsistent-results-1"
              title={row3Content[1].title}
            />
            <S.ImageCaption>
              {row3Content[1].reference.names}
              <em>{` ${row3Content[1].reference.journal} `}</em>
              {row3Content[1].reference.location}
            </S.ImageCaption>
          </S.ImageSmallNode>
          <S.ImageSmallNode>
            <S.ImageTitle>{row3Content[2].title}</S.ImageTitle>
            <S.Image
              src={row3Content[2].image}
              loading="lazy"
              alt="inconsistent-results-2"
              title={row3Content[2].title}
            />
            <S.ImageCaption>
              {row3Content[2].reference.names}
              <em>{` ${row3Content[2].reference.journal} `}</em>
              {row3Content[2].reference.location}
            </S.ImageCaption>
          </S.ImageSmallNode>
        </S.ImageContainer>
        <S.FooterImage
          src={row3Content[0].image}
          loading="lazy"
          alt="traditional-scientist"
          title="Traditional Scientist"
        />
      </S.Row3>
    </S.Table>
  );
};

WhyCloudLabReproducibilityTraditional.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabReproducibilityTraditional.defaultProps = {};

export default WhyCloudLabReproducibilityTraditional;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableNodes = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  padding: 3rem;
`;
S.Row1 = styled.div`
  display: flex;
`;
S.Row2 = styled.div`
  display: flex;
`;
S.Row3 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 95rem;
  position: relative;

  @media ${device.md} {
    flex: 0 0 62.8rem;
    flex-direction: row;
  }
`;
S.Row1TextNode = styled(S.TableNodes)`
  flex: 1;
  background: #e5e8ea;
  color: #1f2222;
  padding: 1.25rem 4rem 1.25rem 2.5rem;
`;
S.Row3TextNode = styled(S.TableNodes)`
  display: flex;

  @media ${device.md} {
    margin-right: 0.5rem;
    flex: 1.25;
  }
`;
S.ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  flex: 1;

  @media ${device.md} {
    margin-top: 0;
    flex-direction: column;
  }
`;
S.ImageSmallNode = styled(S.TableNodes)`
  flex: 1;
  line-height: 1.7rem;
  padding: 2rem;
  &:last-child {
    margin-top: 0;
    margin-left: 0.5rem;
  }

  @media ${device.md} {
    padding: 3rem 3rem 3rem 5rem;
    &:last-child {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }
`;
S.BulletsNode = styled.div`
  flex: 1;
`;
S.VideoPlayer = styled(ReactPlayer)`
  background-color: #000000;
  height: 0;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 45%;
  position: relative;

  iframe {
    left: 0;
    position: absolute;
    top: 0;
  }
`;
S.Image = styled.img`
  display: block;
  max-width: 100%;
`;
S.ImageTitle = styled.div`
  color: #c89f58;
  font-weight: bold;
`;
S.ImageCaption = styled.div`
  color: #949ea7;
  word-break: break-all;

  @media (min-width: 480px) {
    word-break: normal;
  }
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -7rem;
  max-width: 100%;
`;
