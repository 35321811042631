import React from "react";
import WhyCloudLabReproducibilityCloud from "components/WhyCloudLabsTables/reproducibility/reproducibility-cloud";
import WhyCloudLabReproducibilityTraditional from "components/WhyCloudLabsTables/reproducibility/reproducibility-traditional";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsInPageNav,
  WhyCloudLabsNav,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabReproducibilityPage = ({ data, location }) => {
  const title = "Push-Button Experimental Reproducibility";
  const description =
    "Reproduce any experiment at any time at the push of a button";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsInPageNav
          data={data.inPageNav.traditional}
          kind={"traditional"}
          tab={location && location.hash}
        >
          <WhyCloudLabReproducibilityTraditional
            data={data.inTable.traditional}
          />
        </WhyCloudLabsInPageNav>
        <WhyCloudLabsInPageNav
          data={data.inPageNav.cloud}
          kind={"cloud"}
          tab={location && location.hash}
        >
          <WhyCloudLabReproducibilityCloud data={data.inTable.cloud} />
        </WhyCloudLabsInPageNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabReproducibilityPage;

export const query = graphql`
  query WhyCloudLabReproducibilityPage {
    inPageNav: whyCloudLabsJson(section: { eq: "reproducibility" }) {
      traditional {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
      cloud {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
    }
    inTable: whyCloudLabsJson(section: { eq: "reproducibility" }) {
      traditional {
        content {
          text
          list {
            text
          }
          videoURL
          controls {
            controls
          }
          image
          title
          reference {
            names
            journal
            location
          }
        }
      }
      cloud {
        content {
          header
          text
          image
        }
      }
    }
  }
`;
