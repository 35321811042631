import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList } from "../components";
import { device } from "utils";

const WhyCloudLabReproducibilityCloud = ({ data }) => {
  const {
    content: [
      row1Content,
      row2Content,
      row3Content,
      row4Content,
      row5Content,
      row6Content,
      row7Content,
    ],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Image
          src={row1Content[0].image}
          loading="lazy"
          alt="reproducibility-cloud-banner"
          title="Experiments are Codified"
        />
      </S.Row1>
      <S.Row2>
        <S.Row2Header>{row2Content[0].header}</S.Row2Header>
        <S.BulletsNode>
          {BulletList({
            data: row2Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row2>
      <S.Row3>
        <S.BulletsNode>
          {BulletList({
            data: row3Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row3Header>{row3Content[0].header}</S.Row3Header>
      </S.Row3>
      <S.Row4>
        <S.Row4Header>{row4Content[0].header}</S.Row4Header>
        <S.BulletsNode>
          {BulletList({
            data: row4Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row4>
      <S.Row5>
        <S.BulletsNode>
          {BulletList({
            data: row5Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row5Header>{row5Content[0].header}</S.Row5Header>
      </S.Row5>
      <S.Row6>
        <S.Row6Header>{row6Content[0].header}</S.Row6Header>
        <S.BulletsNode>
          {BulletList({
            data: row6Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row6>
      <S.Row7>
        <S.BulletsNode className="row7">
          {BulletList({
            data: row7Content,
            level: 0,
          })}
        </S.BulletsNode>
        <S.Row7Header>{row7Content[0].header}</S.Row7Header>
        <S.FooterImage
          src={row7Content[0].image}
          loading="lazy"
          alt="cloud-scientist"
          title="Cloud Scientist"
        />
      </S.Row7>
    </S.Table>
  );
};

WhyCloudLabReproducibilityCloud.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabReproducibilityCloud.defaultProps = {};

export default WhyCloudLabReproducibilityCloud;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableHeaders = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.greenLink};
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
  padding: 3rem;
`;
S.Row1 = styled.div`
  display: flex;
  align-items: center;
`;
S.Row2 = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column-reverse;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row4 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row5 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column-reverse;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row6 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }
`;
S.Row7 = styled.div`
  display: flex;
  flex: 0 0 80rem;
  margin-top: 0.5rem;
  flex-direction: column-reverse;
  position: relative;

  @media ${device.md} {
    flex: 0 0 68rem;
    flex-direction: row;
  }
`;
S.Row2Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 7rem 12.4rem 0 4.6rem;
    flex: 1;
  }
`;
S.Row3Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 6rem 5.7rem;
    flex: 1;
  }
`;
S.Row4Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 4rem 7rem 4rem 4.5rem;
    flex: 1;
  }
`;
S.Row5Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 7.5rem 5.7rem 0 5.7rem;
    flex: 1;
  }
`;
S.Row6Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 4.5rem 4rem;
    flex: 3;
  }
`;
S.Row7Header = styled(S.TableHeaders)`
  @media ${device.md} {
    padding: 8rem 5.6rem 0 5.6rem;
    flex: 3;
  }
`;
S.BulletsNode = styled.div`
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  background: #000000;
  flex: 2;
  display: flex;
  align-items: center;
  padding: 3rem 2rem;
  &.row7 {
    align-items: flex-start;
    padding: 6rem 4.8rem 0 4.8rem;
  }

  @media ${device.md} {
    padding: 3rem;
  }
`;
S.Image = styled.img`
  max-width: 100%;
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  right: 1rem;
  max-width: 100%;
`;
